<template>
  <div>
    <div>
      <el-select
        v-model="searchType"
        slot="prepend"
        placeholder="选择类型"
        size="mini"
      >
        <el-option label="微信昵称" :value="1"></el-option>
        <el-option label="UID" :value="2"></el-option>
      </el-select>
      <el-input
        class="margin-l-sm"
        v-model="studentName"
        size="mini"
        style="width: 160px"
        clearable
      ></el-input>

      <el-button class="margin-l-sm" size="mini" @click="search"
        >筛选</el-button
      >

      <el-button
        class="fl-r"
        type="primary"
        icon="el-icon-plus"
        size="mini"
        @click="showAddStudent"
        >添加学员
      </el-button>
      <div class="clear"></div>
    </div>
    <div class="margin-t-sm">
      <div class="table-content">
        <el-table
          :header-cell-style="{ padding: 0 }"
          ref="singleTable"
          :data="tableData"
          highlight-current-row
          v-loading="loading"
          style="width: 100%; background: #fff"
        >
          <el-table-column label="ID" property="id" width="80">
          </el-table-column>
          <el-table-column width="80" label="头像">
            <template slot-scope="scope">
              <el-avatar :src="scope.row.user_name_avatar_url"></el-avatar>
            </template>
          </el-table-column>
          <el-table-column label="用户id">
            <template slot-scope="scope">
              <span>{{ scope.row.user_id }}</span>
            </template>
          </el-table-column>
          <el-table-column label="名称">
            <template slot-scope="scope">
              <span>{{ scope.row.user_name }}</span>
            </template>
          </el-table-column>
          
          <el-table-column property="created_at" label="加入时间" width="200">
          </el-table-column>
          <el-table-column property="index" label="已学课时" width="120">
            <template slot-scope="scope">
              <span>{{ scope.row.study_class }}</span>
            </template>
          </el-table-column>
          <el-table-column property="index" label="作业限制数量" width="120">
            <template slot-scope="scope">
              <span>{{
                scope.row.work_number === 0 ? "无限制" : scope.row.work_number
              }}</span>
            </template>
          </el-table-column>
          <el-table-column property="index" label="学习进度" width="120">
            <template slot-scope="scope">
              <span>
                <el-link type="primary" @click="studyProgress(scope.row)">
                  {{ percentage(scope.row.study_class / classTotal) }}</el-link
                >
              </span>
            </template>
          </el-table-column>
          <el-table-column property="index" label="截止时间" width="200">
            <template slot-scope="scope">
              <span>{{ scope.row.end_at }}</span>
            </template>
          </el-table-column>
          <el-table-column property="address" label="状态" width="200">
            <template slot-scope="scope">
              <span v-if="scope.row.status == 1" class="state-up"
                ><i></i>正常</span
              >
              <span v-if="scope.row.status == 0" class="state-down"
                ><i></i>限制访问</span
              >
            </template>
          </el-table-column>
          <el-table-column label="备注" property="remark" width="200">
          </el-table-column>
          <el-table-column label="创建人" property="creator"> </el-table-column>
          <el-table-column property="address" label="操作">
            <template slot-scope="scope">
              <span>
                <el-popover class="margin-l-sm" placement="left" width="390">
                  <div
                    class="padding-sm"
                    style="
                      display: flex;
                      justify-content: center;
                      align-items: center;
                    "
                  >
                    <p
                      class="fl-l margin-r-sm"
                      style="height: 30px; line-height: 30px"
                    >
                      最大提交数
                    </p>
                    <p class="fl-l">
                      <el-input
                        size="mini"
                        placeholder="0为无限制"
                        v-model="scope.row.work_number"
                        controls-position="right"
                      ></el-input>
                    </p>
                    <div class="fl-l margin-l-sm">
                      <el-button
                        type="primary"
                        size="mini"
                        @click="setNumber(scope.row)"
                        :loading="setLoading"
                        >确定</el-button
                      >
                    </div>
                    <div class="clear"></div>
                  </div>
                  <el-link slot="reference" type="primary">作业限制</el-link>
                </el-popover>
              </span>
              -
              <span>
                <el-link
                  v-if="scope.row.status == 1"
                  type="primary"
                  @click="setStatus(scope.row, scope.$index)"
                  >限制访问</el-link
                >
                <el-link
                  v-if="scope.row.status == 0"
                  type="primary"
                  @click="setStatus(scope.row, scope.$index)"
                  >取消限制</el-link
                >
              </span>
              -
              <span>
                <el-popconfirm
                  icon="el-icon-info"
                  icon-color="red"
                  title="删除后将不可恢复，确定删除吗？"
                  @confirm="deleted(scope.row)"
                >
                  <el-link slot="reference" type="primary">删除</el-link>
                </el-popconfirm>
              </span>
              <span>
                -
                <el-popover placement="bottom" width="200" trigger="click">
                  <div class="padding-sm" v-loading="workCountLoading">
                    已提交作业数量：{{ workCount }}
                  </div>
                  <el-link
                    type="primary"
                    slot="reference"
                    @click="getWorkCount(scope.row)"
                    >作业数量
                  </el-link>
                </el-popover>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="table-batch">
        <span class="fl-r padding-b-sm">
          <el-pagination
            small
            background
            :current-page="page"
            :page-sizes="[10, 20]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="setPageSize"
            @current-change="setPage"
            :total="total"
          >
          </el-pagination>
        </span>
        <div style="clear: both"></div>
      </div>
    </div>

    <el-dialog
      title="添加学员"
      append-to-body
      :show-close="false"
      :close-on-press-escape="false"
      :close-on-click-modal="false"
      :visible.sync="showAddStudentDialog"
      width="50%"
    >
      <div>
        <div class="padding-sm">
          <el-select
            v-model="searchType"
            slot="prepend"
            placeholder="选择类型"
            size="mini"
          >
            <el-option label="微信昵称" :value="1"></el-option>
            <el-option label="UID" :value="2"></el-option>
          </el-select>
          <el-input
            class="margin-l-sm"
            v-model="studentName"
            size="mini"
            style="width: 160px"
            clearable
          >
          </el-input>
          <el-button
            class="margin-l-sm"
            size="mini"
            @click="searchStudent"
            type="primary"
            :loading="searchStudentBtn"
            >筛选</el-button
          >
        </div>

        <div class="margin-t-sm" v-if="notData">暂无数据</div>
        <div class="margin-t-sm">
          <el-tag
            class="margin-b-sm margin-r-sm"
            effect="plain"
            :key="student.id"
            v-for="student in searchStudents"
          >
            <div style="display: flex; align-items: center; padding: 5px 0">
              <span>UID:{{ student.id }}</span>
              <el-avatar
                class="margin-l-sm"
                shape="square"
                size="small"
                :src="student.avatar_url"
              >
              </el-avatar>
              <span class="margin-l-sm">{{ student.name }}</span>
              <span
                class="margin-l-sm el-icon-plus icon-hover"
                @click="selectStudents(student)"
              ></span>
            </div>
          </el-tag>
        </div>

        <div v-if="selectStudent.length > 0">
          <p class="margin-t-sm margin-b-sm">已选学员</p>
          <div
            style="
              border: 1px solid #eee;
              padding: 10px 10px 0 10px;
              border-radius: 10px;
            "
          >
            <el-tag
              type="success"
              closable
              class="margin-b-sm margin-r-sm"
              effect="plain"
              :key="student.id"
              v-for="student in selectStudent"
              @close="subStudent(student)"
            >
              <div class="fl-l">
                <div style="display: flex; align-items: center; padding: 5px 0">
                  <el-avatar
                    shape="square"
                    size="small"
                    :src="student.avatar_url"
                  ></el-avatar>
                  <span class="margin-l-sm">{{ student.name }}</span>
                </div>
              </div>
            </el-tag>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="hideAddStudent" size="mini">取 消</el-button>
        <el-button type="primary" @click="addStudent" size="mini"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import tools from "../../../utils/tools";
export default {
  props: ["classTotal", "courseName"],
  name: "student",
  data() {
    return {
      searchStudentBtn: false,
      studentName: "",
      searchType: 1,
      showAddStudentDialog: false,
      searchName: "",
      total: 0,
      loading: true,
      page: 1,
      pageSize: 10,
      name: "",
      tableData: [],
      id: 0,
      searchStudents: [],
      notData: false,
      selectStudent: [],
      setLoading: false,
      workCountLoading: false,
      workCount: 0,
    };
  },
  watch: {
    classTotal: function (val) {},
    courseName: function (val) {
      console.log(val);
    },
  },
  methods: {
    ...mapActions("course", [
      "getCourseStudent",
      "setCourseUserStatus",
      "addCourseStudent",
      "setWorkNumber",
    ]),
    ...mapActions("user", ["getUserList"]),
    ...mapActions("common", ["delete"]),
    ...mapActions("other", ["getWorkList"]),
    setNumber(row) {
      this.setLoading = true;
      this.setWorkNumber({ id: row.id, workNumber: row.work_number })
        .then((res) => {
          this.$message.success("操作成功");
        })
        .finally(() => {
          this.setLoading = false;
        });
    },
    search() {
      this.page = 1;
      this.getCourseStudentList();
    },
    searchStudent() {
      let searchData = {};
      searchData.page = 1;
      if (this.searchType === 1) {
        searchData.name = this.studentName;
        searchData.pageSize = 1000;
      } else {
        searchData.uid = this.studentName;
        searchData.pageSize = 1000;
      }
      console.log(searchData);
      if (this.studentName === "") {
        this.$message.error("请输入昵称");
        return;
      }
      this.notData = false;
      this.searchStudentBtn = true;
      this.getUserList(searchData).then((res) => {
        if (res.data.list.length === 0) {
          this.notData = true;
        }
        this.searchStudents = res.data.list;
        this.searchStudentBtn = false;
      });
    },
    showAddStudent() {
      this.showAddStudentDialog = true;
    },
    hideAddStudent() {
      this.showAddStudentDialog = false;
    },
    async selectStudents(student) {
      let data = {
        userId: student.id,
        id: this.id,
      };
      let res = await this.getCourseStudent(data);

      if (res.data.list.length > 0) {
        this.$message.info("该学员已经加入过这个课程了！");
      }

      console.log(res.data.total);
      if (
        JSON.stringify(this.selectStudent).indexOf(JSON.stringify(student)) ==
        -1
      ) {
        this.selectStudent.push(student);
      }
    },
    subStudent(student) {
      console.log(student);
      this.selectStudent.splice(this.selectStudent.indexOf(student), 1);
    },
    addStudent() {
      console.log(this.selectStudent);
      let userIds = [];
      let data = {
        courseId: this.id,
        userIds: userIds,
      };
      this.selectStudent.forEach((item) => {
        userIds.push(item.id);
      });
      console.log(data);
      this.addCourseStudent(data)
        .then((res) => {
          if (res.ret === 0) {
            this.$message.success("添加成功！");
            this.page = 1;
            this.name = "";
            this.searchStudents = [];
            this.notData = false;
            this.selectStudent = [];
            this.studentName = "";
            this.getCourseStudentList();
          }
        })
        .finally(() => {
          this.showAddStudentDialog = false;
        });
    },
    getCourseStudentList() {
      this.loading = true;

      let data = {
        id: this.id,
        page: this.page,
        pageSize: this.pageSize,
      };
      if (this.searchType === 1) {
        data.name = this.studentName;
      } else {
        data.userId = this.studentName;
      }
      this.getCourseStudent(data).then((res) => {
        if (res.ret == 0) {
          this.total = res.data.total;
          this.tableData = res.data.list;
          this.loading = false;
        }
      });
    },
    studyProgress(row) {
      this.$router.push(
        "/studyProgress/" + row.course_id + "/userId/" + row.user_id
      );
      console.log(row);
    },
    percentage(p) {
      return tools.percentage(p);
    },
    setPageSize(pageSize) {
      this.pageSize = pageSize;
      this.getCourseStudentList();
    },
    setPage(page) {
      this.page = page;
      this.getCourseStudentList();
    },
    setStatus(row, rowIndex) {
      let _this = this;
      let data = {
        id: row.id,
        status: !row.status,
      };

      this.loading = true;
      this.setCourseUserStatus(data).then(
        (success) => {
          _this.tableData[rowIndex].status = success.data.status;

          _this.$message({
            message: "设置成功",
            type: "success",
          });
          this.loading = false;
        },
        (error) => {
          _this.$message({
            message: "设置失败",
            type: "error",
          });
          this.loading = false;
        }
      );
    },
    deleted(row, rowIndex) {
      let _this = this;
      this.loading = true;
      this.delete({ id: row.id, model: "UserCourse" }).then((res) => {
        this.getCourseStudentList();
        if (res.ret == 0) {
          this.$message.success("删除成功");
        }
      });
    },
    getWorkCount(row) {
      console.log(row.user_name);
      this.workCountLoading = true;
      let data = {
        title: this.courseName,
        name: row.user_name,
        type: 0,
      };
      this.getWorkList(data)
        .then((res) => {
          console.log(res);
          this.workCount = res.data.total;
        })
        .finally(() => {
          this.workCountLoading = false;
        });
      console.log(this.courseName);
    },
  },
  mounted() {
    this.id = this.$route.params.id;
    this.getCourseStudentList();
  },
};
</script>

<style>
.icon-hover {
  cursor: pointer;
  font-size: 14px;
}

.icon-hover:hover:before {
  font-size: 14px;
  content: "\e7a0";
}

.el-tag {
  height: auto;
  line-height: auto;
}

.el-tag .el-icon-close {
  top: 5px;
}
</style>
